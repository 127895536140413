.loading {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9;
  background: #031e32;

  img {
    display: inline-block;
    width: 200px;
    height: 200px;
    margin: 8px;
    background: #fff;
    opacity: 0;
    animation: opacity-animation 0.6s cubic-bezier(0, 0.2, 0.8, 1);
  }
}

.fadeOut {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 9;
  background: #031e32;
  pointer-events: none;
  opacity: 0;
  animation: opacity-animation 0.4s ease-out;
}

@keyframes opacity-animation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
