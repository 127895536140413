.banner {
  background: #001018;
  color: white;
  display: flex;
  flex-wrap: wrap;
  left: 0px;
  position: fixed;
  width: calc(100% - 30px);
  z-index: 999;
  bottom: 0px;
  flex-direction: row;
  padding: 15px;
  justify-content: center;

  &__container {
    width: 100%;
    max-width: 600px;
  }

  &__buttonWrapper {
    width: 100%;
    display: flex;
  }

  &__button {
    margin-left: auto;
  }

  &__text {
    display: flex;
    flex-direction: column;

    font-family: "Gotham A", "Gotham B";

    h3 {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin: 0;
    }

    p {
      margin-left: 3rem;
      font-size: 12px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    width: 100%;

    font-family: "Gotham A", "Gotham B";

    &__buttons {
      align-self: flex-end;
      font-size: 12px;

      button {
        font-family: inherit;
        font-size: 12px;
        // color: #35bdf6;

        margin: 0 10px;
        padding: 8px 12px;

        cursor: pointer;
        background: #35bdf6;
        border: none;
        color: white;
        border-radius: 2px;

        &:first-child {
          background: gray;
          color: white;
        }
      }
    }

    &__checkboxes {
      font-size: 12px;

      margin-top: 20px;
      padding: 10px 15px;
      // border: 3px solid white;
      border-radius: 2px;

      width: 80%;
      display: flex;
      justify-content: space-between;
      align-self: center;
      flex-wrap: wrap;

      &__item {
        display: flex;
        align-items: center;

        margin-bottom: 4px;
      }
    }
  }
}
