.navbar {
  width: 100%;
  height: 75px;
  display: flex;
  z-index: 5;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-family: "Gotham A", "Gotham B";
    font-weight: 500;
    font-style: normal;
    font-size: 11px;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    background: #001018;
    width: calc((100% / 5) - 1px);
    padding: 24px 0px;
    transition: all 0.3s;
    border-left: 1px solid #0e1e23;

    &:hover {
      background: #35bdf6;
    }
  }

  img {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media (max-width: 990px) {
  //.navbar__item:nth-child(5) {
  //	display: none;
  //}

  //.navbar__item {
  //	width: 20%;
  //}
}

@media (max-width: 670px) {
  .navbar__item {
    width: 25%;
  }
}

@media (max-width: 468px) {
  .navbar__item {
    font-size: 10px;
  }
}
