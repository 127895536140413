.hamburgerButton {
	border: none;
	cursor: pointer;
	z-index: 4;
	background: #001018;

	&__main {
		position: fixed;
		top: 50px;
		right: 30px;
		background: transparent;
	}

	&__secondary {
		background: #35bdf6;
		margin: 0;
		border: none;
		outline: none;
		box-shadow: none;
		border-left: 1px solid #0e1e23;
	}
}

.hamburgerMenu {
	position: fixed;
	z-index: 3;
	width: 100vw;
	height: 100vh;
	background-color: #031e32;
	background-image: linear-gradient(to top left, #123458, #071626);
	text-align: end;

	&__items {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;
		height: 100vh;
		padding-right: 20%;

		a {
			font-family: "Libre Baskerville";
			font-weight: 400;
			font-size: 30px;
			display: block;
			margin-bottom: 10px;
		}
	}
}

@media (max-width: 380px) {
	.hamburgerMenu__items {
		padding-right: 10%;
	}

	.hamburgerButton {
		top: 10px;
		right: 10px;
	}
}
