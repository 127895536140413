.admin {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 320px;
    margin-bottom: 200px;

    h1 {
      font-size: 48px;
    }

    input {
      margin-bottom: 20px;
      font-size: 20px;
      padding: 6px 12px;
      border: 1px solid gray;
      border-radius: 6px;
      width: calc(100% - 12px);

      &:focus {
        outline: none;
      }
    }

    button {
      background-color: #35bdf6;
      color: white;
      padding: 8px;
      width: 100%;
      border: none;
      cursor: pointer;
    }
  }

  &__createArticle {
    width: 100%;
    max-width: 1040px;
    margin: 50px auto;

    img {
      margin-bottom: 20px;
    }

    h1 {
      margin: auto;
      margin-bottom: 60px;
    }
  }
}

.sun-editor {
  margin-bottom: 20px;
}