.contactpage {
  &__section {
    margin: 100px 90px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 0 auto;

    &__container {
      padding: 100px 90px;
      background: #f6f6f6;
    }

    input {
      outline: red;

      font-family: "Gotham A", "Gotham B";
      font-style: normal;
      background: #fff;
      border-radius: 2px;
      color: #333;
      font-size: 16px;
      border: 1px solid #ccc;
      padding: 16px 10px;
      // width: 100%;
      line-height: 1.3;
      transition: border 0.3s;
      margin: 10px 0;

      &:focus {
        border: 1px solid #999;
        outline: none;
      }
    }

    &__error {
      font-family: "Gotham A", "Gotham B";
      font-style: normal;
      color: red;
      font-size: 13px;
      line-height: 1.3;
    }

    &__success {
      font-family: "Gotham A", "Gotham B";
      font-style: normal;
      color: green;
      font-size: 13px;
      line-height: 1.3;
    }

    &__submitarea {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
    }

    button {
      cursor: pointer;
      background: #35bdf6;
      border: 1px solid #ddd;
      color: #333;
      font-size: 1em;
      padding: 10px 15px;
    }
    button:disabled {
      background: #666;
      color: #999;
    }
  }

  &__button {
    display: flex;
    background: none;
    border: none;
    padding: 0;
  }

  &__link {
    color: #333;
    font-family: "Gotham A", "Gotham B";
    font-weight: 500;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    -webkit-font-smoothing: antialiased;
    transition: color 0.5s;
    margin: 10px 0;

    &:hover {
      color: #35bdf6;
    }
  }

  &__icons {
    display: flex;
    align-items: center;
    margin: 10px 0;

    &__icon {
      margin: 0 15px;
    }
  }
}

.checkbox {
  display: flex;
  flex-direction: row;
  color: #333;
  font-family: "Gotham A", "Gotham B";
  font-weight: 500;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  -webkit-font-smoothing: antialiased;
  transition: color 0.5s;
  margin: 10px 0;

  label {
    width: auto;
  }

  &__link {
    margin-right: 10px;
    color: #333;
  }
}

@media (max-width: 990px) {
  .contactpage__form {
    &__submitarea {
      flex-direction: column;
    }

    button {
      margin-right: auto;
    }
  }
}
