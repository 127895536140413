.service {
  &__mainSection {
    padding-top: 50px;

    img {
      width: 100%;
    }
  }
}

.pb-0 {
  padding-bottom: 0!important;
}