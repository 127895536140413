.home {
  background: #eaeaea;
}

.contact {
  background-image: linear-gradient(to top left, #123458, #071626);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px 0;
  font-family: "Gotham A", "Gotham B";
  font-weight: 500;
  font-style: normal;
  font-size: 17px;
  color: #fff;

  &__left {
    display: flex;
    align-items: center;
  }

  p {
    margin-left: 70px;
  }

  a {
    background: none;
    border: 3px solid white;
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-size: 13px;
    color: inherit;
    margin-left: 30px;
    padding: 12px 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

.contact-new {
  background-color: #35bdf6;
  display: flex;
  gap: 1rem;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px 16px;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-size: 17px;
  color: #fff;

  a {
    border: 2px solid white;
    padding: 12px 32px;
    cursor: pointer;
    font-size: 13px;
    transition: all 0.3s;
    flex-shrink: 0;
  }

  a:hover {
    background-color: white;
    color: #35bdf6;
  }
}

@media (max-width: 670px) {
  .contact {
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__left {
      flex-direction: column;
    }

    svg {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 30px;
      margin-left: 0;
    }

    a {
      margin-left: 0;
    }

    button {
      margin: 0;
    }
  }

  .contact-new {
    flex-direction: column;

    p {
      text-align: center;
    }
  }
}

.floatingBtn {
  position: fixed;
  z-index: 99;
  top: 40px;
  right: 110px;

  @media (max-width: 768px) {
    right: 10px;
    top: 100px;
  }

  visibility: visible;
  width: 180px;
  height: 50px;
  line-height: 50px;
  // border-radius: 50px;
  z-index: 99;
  background-color: #123458;
  font-family: "Libre Baskerville", serif;
  font-weight: 600;
  color: white;
  text-align: center;
  margin: 0;
  padding: 0px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  // border:2px solid white;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #071626;
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }

  p {
    margin-top: 0;
  }
}

.changeLang {
  justify-content: space-around;
  position: fixed;
  z-index: -1;

  left: 20px;
  top: 40px;
  width: 30px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  z-index: 98;
  font-family: "Libre Baskerville", serif;
  font-weight: 600;
  color: white;
  text-align: center;
  align-items: center;
  padding: 0px;

  img {
    width: 30px;
    height: 30px;
    margin-top: auto;
    margin-bottom: auto;
  }
}
