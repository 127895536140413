.article {
	padding: 100px 90px;
	max-width: 990px;

	&__wrapper {
		display: flex;
		justify-content: center;
	}

	p {
		color: #333;
		font-family: "Gotham A", "Gotham B";
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 25px;
		-webkit-font-smoothing: antialiased;
	}

	&__top {
		display: flex;
		flex-direction: column;

		img {
			align-self: center;
			width: 100%;
		}

		h1 {
			width: fit-content;
			font-family: "Libre Baskerville", serif;
			color: #031e32;
			font-style: normal;
			font-weight: 400;
			font-size: 65px;
			line-height: 1.1;
			letter-spacing: -2px;
			margin: 0;
		}
	}

	&__content {
		h3 {
			color: #031e32;
			font-family: "Libre Baskerville", serif;
			font-style: normal;
			font-weight: 400;
			font-size: 37px;
			line-height: 1.1;
			letter-spacing: -2px;
			width: fit-content;
			margin: 0;
			padding-bottom: 10px;
		}

		hr {
			margin: 24px 0;
		}
	}
}

@media (max-width: 990px) {
	.article {
		padding: 100px 30px;

		&__top {
			max-width: 100%;

			h1 {
				font-size: 52px;
			}
		}
	}
}

@media (max-width: 670px) {
	.article__top h1 {
		font-size: 44px;
	}
}

@media (max-width: 468px) {
	.article__top h1 {
		font-size: 40px;
	}
}
