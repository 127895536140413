.about {
	&__members__top {
		display: flex;

		h3 {
			color: #777;
			font-family: "Gotham A", "Gotham B";
			font-weight: 500;
		}
	}

	&__members {
		&__items {
			display: flex;
			flex-wrap: wrap;
			padding-top: 30px;
		}

		&__item {
			width: 33.33%;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 60px;

			h4 {
				margin: 10px 0 0;
			}

			p {
				margin: 0;
			}

			img {
				width: 200px;
				height: 200px;
				border-radius: 50%;
				object-fit: cover;
				border: 3px solid #083457;
			}
		}
	}

	&__technologies {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		// background-image: linear-gradient(to top left, #123458, #071626);
		background: #f6f6f6;
		padding-bottom: 50px;

		&__items {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 0 50px;
		}

		img {
			width: 17%;
			padding: 0 1.5%;
		}
	}

	&__contact {
		padding: 80px 60px;

		background-image: linear-gradient(to top left, #123458, #071626);

		h1 {
			color: #fff !important;
		}

		p {
			color: #fff !important;
		}

		a {
			margin-top: 20px;
			background-color: #35bdf6;
			border: none;
			color: white;
			width: fit-content;
			padding: 15px 20px;
			font-weight: bold;
			cursor: pointer;
		}
	}
}

@media screen and (max-width: 720px) {
	.about__members {
		&__top {
			display: block;
			width: 100%;

			h3 {
				text-align: left;
			}

			p {
				max-width: 100%;
			}
		}

		&__item {
			width: 50%;
		}
	}
}

@media screen and (max-width: 420px) {
	.about__members__item {
		width: 100%;
	}
}

@media screen and (max-width: 700px) {
	.about__technologies img {
		width: 47%;
	}
}

@media screen and (max-width: 800px) and (min-width: 420px) {
	.about__members__item img {
		width: 150px;
		height: 150px;
	}
}
