.servicesSection {
  padding: 50px 90px 0;
  background-color: #f6f6f6;

  &__container {
    // display: grid;
    // grid-template-columns: 30% 30% 30%;
    // grid-template-rows: auto;
    // justify-content: center;
    padding: 0 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &__item {
    width: 47%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    height: 100%;
    padding-bottom: 50px;

    img {
      height: 60px;
      width: 60px;
      margin-bottom: 20px;
    }

    &__title {
      margin: 0;
      padding: 0;
      font-family: "Libre Baskerville", serif;
      font-style: normal;
      font-weight: 400;
      font-size: 35px;
      line-height: 1.1;
      letter-spacing: -1px;
      color: #333;
      padding-bottom: 10px;
    }

    &__description {
      margin: 0;
      padding: 0;
      height: 100%;
      font-family: "Gotham A", "Gotham B";
      font-style: normal;
      // font-weight: 500;
      font-size: 15px;
      line-height: 25px;
      color: #333;
      padding-bottom: 20px;
      margin-bottom: 10px;
    }
  }

  .new-service-section {
    .slider-buttons {
      padding-bottom: 2rem;
      display: flex;
      gap: 1rem;
      align-items: center;

      .slider-button {
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #35bdf6;
        background-color: transparent;
        color: #35bdf6;
        border-radius: 50%;
        cursor: pointer;

        &__prev {
          transform: rotate(90deg);
          display: flex;
          justify-content: center;
          margin-right: 2px;
        }

        &__next {
          transform: rotate(-90deg);
          display: flex;
          justify-content: center;
          margin-left: 2px;
        }
      }

      .slider-point {
        width: 8px;
        height: 8px;
        border: 1px solid #35bdf6;
        border-radius: 50%;

        &.active {
          background-color: #35bdf6;
        }
      }
    }
  }

  .new-item {
    .image-wrapper {
      width: 100%;
      height: 240px;
      position: relative;

      .image {
        position: absolute;
        inset: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    h3 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }
  }

  .benefits-container {
    padding: 48px 140px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;

    h4 {
      font-size: 20px;
    }

    img {
      height: 100px;
      width: 100px;
    }
  }
}

swiper-slide {
  width: 27%;
}

@media (max-width: 1084px) {
  .servicesSection .benefits-container {
    grid-template-columns: auto;
  }

  swiper-slide {
    width: 40%;
  }
}

@media (max-width: 990px) {
  .servicesSection__container {
    padding: 0 50px;
  }

  .servicesSection .benefits-container {
    padding: 0 50px;
  }
}

@media (max-width: 990px) {
  .servicesSection {
    padding: 100px 30px;

    &__item {
      width: 100%;
    }
  }
}

@media (max-width: 670px) {
  .servicesSection {
    &__item {
      &__title {
        font-size: 28px;
      }
    }
  }

  swiper-slide {
    width: 80%;
    cursor: grab;
  }
}

@media (max-width: 468px) {
  .servicesSection {
    padding: 100px 16px;

    &__item {
      img {
        width: 50px;
        height: 50px;
      }
    }

    &__container {
      padding: 0 15px;
    }

    .servicesSection .benefits-container {
      padding: 0 15px;
    }
  }
}
