.privacypolicy {
  margin: 100px 90px;
  display: flex;
  justify-content: center;
  font-family: "Gotham A", "Gotham B";
  font-style: normal;

  h3,
  h5 {
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 1px;
    color: #777;
    font-family: "Gotham A", "Gotham B";
    font-weight: 500;
    margin-right: 80px;
  }
  h5 {
    color: #031e32;
  }

  &__content {
    margin-left: 10px;
  }

  ul {
    list-style-type: circle;
  }

  &__link {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    color: #000;
    font-family: "Gotham A", "Gotham B";
    font-style: normal;
  }
}

@media (max-width: 990px) {
  .privacypolicy {
    flex-direction: column;
  }
}
