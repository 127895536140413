.projects {
  padding: 100px 90px;

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__item {
    height: 240px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    transition: all 1.2s;
    position: relative;
    padding-top: calc(56.25% / 2);

    &:hover {
      background-size: 130%;
    }

    &__overlay {
      position: absolute;
      top: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      z-index: 1;
      transition: all 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: rgba(18, 52, 88, 0.85);
        background-size: 150% !important;

        span {
          display: block;
        }
      }

      span {
        display: none;
        color: #fff;
        font-size: 36px;
        text-shadow: 0px 3px 5px gray;
      }
    }

    &__overlay2 {
      position: absolute;
      top: 0;
      overflow: auto;
      width: 100%;
      height: 100%;
      z-index: 1;
      transition: all 0.3s;

      &:hover {
        background-color: rgba(18, 52, 88, 0.85);
        background-size: 150% !important;

        .content {
          display: block;
        }
      }

      .content {
        display: none;
        color: #fff;
        padding: 1rem 2rem;

        h3 {
          font-size: 24px;
        }

        .bold {
          font-weight: bold;
        }

        p {
          font-size: 12px;
        }
      }
    }
  }

  &__item2 {
    height: 200px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    transition: all 1.2s;
    position: relative;
    padding-top: calc(56.25%);
    margin-bottom: 50px;

    &:hover {
      background-size: 130%;
    }

    &__overlay {
      position: absolute;
      top: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      z-index: 1;
      transition: all 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        background-size: 150% !important;

        span {
          display: block;
        }
      }

      span {
        display: none;
        color: #fff;
        font-size: 36px;
        text-shadow: 0px 3px 5px gray;
      }
    }
  }

  &__item3 {
    height: 200px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    transition: all 1.2s;
    position: relative;
    padding-top: calc(56.25% / 2);
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 50px;

    &:hover {
      background-size: 130%;
    }

    &__overlay {
      position: absolute;
      top: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      z-index: 1;
      transition: all 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        background-size: 150% !important;

        span {
          display: block;
        }
      }

      span {
        display: none;
        color: #fff;
        font-size: 36px;
        text-shadow: 0px 3px 5px gray;
      }
    }
  }

  &__item4 {
    height: 200px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    transition: all 1.2s;
    position: relative;
    padding-top: calc(56.25%);
    margin-bottom: 50px;

    &:hover {
      background-size: 130%;
    }

    &__overlay {
      position: absolute;
      top: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      z-index: 1;
      transition: all 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        background-size: 150% !important;

        span {
          display: block;
        }
      }

      span {
        display: none;
        color: #fff;
        font-size: 36px;
        text-shadow: 0px 3px 5px gray;
      }
    }
  }
}

@media (min-width: 1400px) {
  .projects__item__overlay2 {
    .content p {
      font-size: 16px;
    }
  }
}

@media (max-width: 990px) {
  .projects {
    padding: 100px 30px;

    &__container {
      grid-template-columns: 100%;
    }
  }

  .projects__item__overlay2 {
    .content p {
      font-size: 16px;
    }
  }
}

@media (max-width: 600px) {
  .projects__item {
    width: 100%;
    padding-top: 56.25%;
  }
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #0c2240;

  &:hover {
    cursor: pointer;
    color: lightgray;
  }
}

@media (max-width: 480px) {
  .projects__item__overlay2 {
    .content p {
      font-size: 12px;
    }
  }
}
