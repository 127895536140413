.services {
  padding: 100px 90px;

  &__container {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    justify-content: center;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    height: 100%;

    &__link {
      margin-bottom: 40px;
      display: block;
    }

    &__wrapper {
      position: relative;
      padding-top: 56.25%;
      margin-bottom: 10px;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        object-fit: cover;
      }
    }

    &__blogImg {
      width: 100%;
      margin-bottom: 10px;
    }

    &__title {
      margin: 0;
      padding: 0;
      font-family: "Libre Baskerville", serif;
      font-style: normal;
      font-weight: 400;
      font-size: 35px;
      line-height: 1.1;
      letter-spacing: -1px;
      color: #333;
      padding-bottom: 10px;
    }

    &__description {
      height: 100%;
      font-family: "Gotham A", "Gotham B";
      font-style: normal;
      // font-weight: 500;
      font-size: 15px;
      line-height: 25px;
      color: #333;
      padding-bottom: 20px;
    }

    &__more {
      font-family: "Gotham A", "Gotham B";
      font-style: normal;
      font-weight: 500;
      color: #555;
      background: none;
      border: 3px solid #555;
      padding: 12px 40px;
      cursor: pointer;
      font-size: 12px;
      display: inline-block;
      transition: color 0.5s, border 0.5s;
      width: fit-content;

      &:hover {
        color: #35bdf6;
        border: 3px solid #35bdf6;
      }
    }
  }

  &__item-new {
    padding: 2rem;

    .image-container {
      height: 100px;
      width: 100px;
      margin-bottom: 3rem;
      position: relative;

      .image {
        position: absolute;
        inset: 0;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    &:nth-child(1) {
      border-radius: 1rem 0 0 1rem;
    }

    &:nth-last-child(1) {
      border-radius: 0 1rem 1rem 0;
    }
  }
}

@media (max-width: 1090px) {
  .services {
    padding: 100px 30px;

    &__container {
      grid-template-columns: 100%;
    }

    &__item-new {
      &:nth-child(1) {
        border-radius: 1rem 1rem 0 0;
      }

      &:nth-last-child(1) {
        border-radius: 0 0 1rem 1rem;
      }
    }
  }
}
