.dropdown{
    text-align: center;
}

.span{
    width: 30px;
    height: 30px;
}

.dropdown .dropdown-btn{
    font-weight: bold;
    color: white;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
}

.dropdown .dropdown-btn h3{
    text-align: end;
    font-family: "Libre Baskerville";
    font-weight: 400;
    font-size: 30px;
}

.dropdown .dropdown-content {
    list-style-type: none;
}

.dropdown .dropdown-item {
    color: white;
    position: relative;
}

.dropdown .dropdown-icon{
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown ul li {
    position: relative;
}

.dropdown ul ul {
    position: absolute;
    width: 100%;
}

.dropdown .dropdown-content .dropdown-item {
    margin: 10px;
    cursor: pointer;
    transition: all 0.2s;
}


.dropdown .dropdown-content .dropdown-item:hover {
    //background: #aa4646;

}