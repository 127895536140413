.pageLanding {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-color: #031e32;

  video {
    position: absolute;
    top: 0;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    overflow: hidden;
  }

  &__filter {
    background-image: linear-gradient(to top left, #123458, #071626);
    opacity: 0.4;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__details {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 130px;
      height: 130px;
    }

    button {
      position: absolute;
      border: 3px solid #35bdf6;
      border-radius: 50%;
      padding: 5px 11px;
      font-size: 24px;
      font-weight: lighter;
      background: transparent;
      color: white;
      cursor: pointer;
      right: 30px;
    }
  }

  &__text {
    color: white;
    width: 50%;
    // max-width: 600px;

    p {
      font-family: "Gotham A", "Gotham B";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 25px;
      -webkit-font-smoothing: antialiased;
    }

    h1 {
      font-family: "Libre Baskerville", serif;
      font-style: normal;
      font-weight: 400;
      font-size: 65px;
      line-height: 1.1;
      letter-spacing: -2px;
      margin-bottom: 20px;
    }

    &__separator {
      width: 50px;
      height: 3px;
      background: #35bdf6;
    }

    &__actions {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      margin-top: 2rem;

      div {
        background-color: #35bdf6;
        columns: white;
        padding: 0.75rem 1.5rem;
        text-transform: uppercase;
        cursor: pointer;
        flex-grow: 1;
        text-align: center;
        transition: all 0.3s;
      }

      div:hover {
        background-color: #123458;
      }
    }
  }

  &__bottomTitle {
    position: absolute;
    bottom: 48px;
    left: 64px;

    color: #fff;
    font-family: "Libre Baskerville", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 1.1;
    letter-spacing: -1px;
    margin: 0;
    padding-bottom: 10px;
  }
}

@media (max-width: 998px) {
  .pageLanding__text h1 {
    font-size: 52px;
  }
}

@media (max-width: 660px) {
  .pageLanding {
    &__text {
      width: 100%;

      h1 {
        font-size: 44px;
      }
    }

    &__details {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 20px;
      width: calc(100% - 40px);
      height: calc(100vh - 40px);
    }
  }
}

@media (max-width: 468px) {
  .pageLanding {
    &__text h1 {
      font-size: 40px;
    }

    &__bottomTitle {
      bottom: 16px;
      left: 32px;
    }
  }
}

@media (min-width: 840px) {
  .pageLanding {
    &__text {
      &__actions {
        div {
          max-width: 200px;
        }
      }
    }
  }
}
