.footer {
  background-image: linear-gradient(to top left, #123458, #071626);
  display: flex;
  flex-direction: column;

  color: #fff;
  font-family: "Gotham A", "Gotham B";
  font-weight: 500;

  &__container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    padding-top: 60px;
  }

  &__button {
    background: none;
    border: none;
    padding: 0;
    margin-right: auto;
  }

  &__link {
    font-family: "Gotham A", "Gotham B";
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    font-size: 14px;
    color: #35bdf6;
    transition: all 0.5s;

    &:hover {
      color: #fff;
    }
  }

  &__logo {
    align-self: flex-start;
    width: 144px;
    margin-left: -16px;
  }

  &__columns {
    display: grid;
    grid-template-columns: 33% 33% 33%;

    &__item {
      display: flex;
      flex-direction: column;
      margin: 20px;

      span {
        padding-bottom: 10px;
        letter-spacing: 1.5px;
        font-size: 14px;
      }
    }
  }

  &__copyright {
    margin: 24px auto;
  }

  &__bottom {
    display: flex;
    flex-direction: row;
  }

  &__policies {
    min-height: 56px;
    width: calc(70% - 40px);
    background: #001018;

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    padding: 0 20px;

    &__link {
      margin: 10px 15px;
      font-size: 11px;
      font-family: "Gotham A", "Gotham B";
      font-style: normal;
    }
  }

  &__icons {
    height: 56px;
    width: calc(30% - 40px);
    background: #35bdf6;

    display: flex;
    align-items: center;

    padding: 0 20px;

    &__icon {
      margin: 0 15px;
    }
  }
}

.footer-contact {
  display: flex;
  justify-content: center;

  .wrapper {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    gap: 3rem;
    background-color: white;
    padding: 2rem 2rem;
    margin-top: 2rem;
    color: black;

    .form,
    .socials {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: center;

      h2 {
        font-family: "Libre Baskerville", serif;
        text-transform: lowercase;
        font-size: 28px;
        font-weight: 400;

        &::first-letter {
          text-transform: capitalize;
        }
      }
    }

    .separator {
      width: 1px;
      height: 100%;
      background-color: black;
    }

    .form {
      input,
      textarea {
        width: 300px;
        padding: 0.75rem 1rem;
      }

      textarea::placeholder {
        font-family: "Gotham A", "Gotham B";
      }

      button {
        border: none;
        color: white;
        background-color: #35bdf6;
        padding: 0.75rem 1.5rem;
        text-transform: uppercase;
        cursor: pointer;
        flex-grow: 1;
        text-align: center;
        transition: all 0.3s;
        align-self: flex-start;
      }

      button:hover {
        background-color: #123458;
      }
    }

    .socials {
      span {
        text-decoration: underline;
        font-weight: lighter;
      }

      .icons {
        display: flex;
        gap: 0.5rem;
      }
    }
  }
}

@media (max-width: 990px) {
  .footer__container {
    width: calc(100% - 40px);
    margin: 0;
    padding: 20px;
    padding-top: 60px;
  }

  .footer__bottom {
    flex-direction: column;
  }

  .footer__policies,
  .footer__icons {
    width: calc(100% - 40px);
  }
}

@media (max-width: 880px) {
  .footer-contact {
    justify-content: stretch;
    padding: 1rem;
    .wrapper {
      width: 100%;
      grid-template-columns: auto;

      .form {
        input,
        textarea {
          width: 90%;
        }
      }
    }
  }
}

@media (max-width: 670px) {
  .footer__columns {
    // margin-left: 20px;

    // flex-direction: column;
    // justify-content: unset;

    // &__column {
    //   margin-bottom: 50px;
    //   align-items: flex-start;
    // }

    grid-template-columns: 50% 50%;
  }
}

@media (max-width: 468px) {
  .footer__columns {
    grid-template-columns: 100%;
  }
}
