.success {
  &__background {
    width: 100%;
    min-height: 70vh;
    display: flex;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__container {
    width: 100%;
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(3, 30, 50, 0.9);
    flex-direction: column;
    gap: 5rem;

    span {
      font-family: "Gotham A", "Gotham B";
      font-style: normal;
      font-weight: 300;
      font-size: 30px;
      letter-spacing: 0;
      line-height: 1.3;
      color: #fff;
    }
  }
}
